import Card from '../../components/Icons/Card';
import Cash from '../../components/Icons/Cash';
import Upi from '../../components/Icons/Upi';

export const Plans_Data = {
  plans: [
    {
      title: 'Basic plan',
      price: '₹10,000',
      tax: '(excluding tax)',
      scans: '50,000 scans',
    },
    {
      popular: 'Most popular',
      title: 'Standard plan',
      price: '₹20,000',
      tax: '(excluding tax)',
      scans: '70,000 scans',
    },
    {
      title: 'Business plan',
      price: '₹30,000',
      tax: '(excluding tax)',
      scans: '100,000 scans',
    },
  ],
};

export const Card_Data = {
  paymentcard: [
    {
      id: 1,
      icon: <Card />,
      title: 'Card',
    },
    {
      id: 2,
      icon: <Cash />,
      title: 'Cash',
    },
    {
      id: 3,
      icon: <Upi />,
      title: 'Upi',
    },
  ],
};
