import React, {useContext, useState} from 'react';
import {AlertMessageContext} from '../../services/Context/alertMessage/alertmessageProvider';
import {useNavigate} from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {CircularProgress} from '@mui/material';
import {colors} from '../../lib/Utils/stringsUtils';
import { LoginContext } from '../../services/Context/userLogin/loginProvider';

export default function AlertDialog({open, close}) {
  const { signOut } = useContext(LoginContext);
  const [loader, setLoader] = useState(false);
  const {showSuccessMessage, showErrorMessage} =
    useContext(AlertMessageContext);
  const navigate = useNavigate();

const onClickLogout = () => {
  setLoader(true);
  new Promise(resolve => setTimeout(resolve, 500))
    .then(() => {
      return signOut();
    })
    .then(() => {
      navigate('/');
      showSuccessMessage('Logout Successfully');
      window.history.pushState(null, window.location.href);
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, window.location.href);
      });
    })
    .catch(error => {
      showErrorMessage('Logout Error');
    })
    .finally(() => {
      setLoader(false);
    });
};


  return (
    <div>
      <Dialog
        PaperProps={{
          style: {
            width: '450px',
          },
        }}
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to logout ?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={close}
            style={{
              background: colors.light_grey,
              color: colors.white,
              padding: '5px 20px',
            }}>
            No
          </Button>
          <Button
            variant="contained"
            disabled={loader}
            onClick={onClickLogout}
            style={{
              padding: '5px 20px',
            }}>
            {loader ? (
              <CircularProgress
                size={28}
                style={{color: colors.primaryColor}}
              />
            ) : (
              'Yes'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
