import React, {createContext, useContext, useEffect, useState} from 'react';
import jwt_decode from 'jwt-decode';
import {Post} from '../../Axios';
import {
  CAPLEAD_ONBOARDING_LOGIN_URL,
  CAPLEAD_ONBOARDING_LOGOUT_URL,
} from '../../Api';
import {AlertMessageContext} from '../alertMessage/alertmessageProvider';

export const LoginContext = createContext();

const LoginProvider = ({children}) => {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState('');
  const [tokendata, setTokendata] = useState(null);
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, showErrorMessage } = useContext(AlertMessageContext);

  const getCookie = name => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  };

  const setUserInformation = cookieToken => {
    if (cookieToken) {
      try {
        const userdata = jwt_decode(cookieToken);
        setUser(userdata);
        setTokendata(cookieToken);
      } catch (error) {
        throw new Error(error);
      }
    }
  };

  useEffect(() => {
    const cookieToken = getCookie('token');
    setUserInformation(cookieToken);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    const cookies = document.cookie.split('; ');
    const usernameCookie = cookies.find(cookie =>
      cookie.startsWith('username='),
    );
    if (usernameCookie) {
      const [, username] = usernameCookie.split('=');
      setUsername(username);
    }
  }, []);


  const signIn = (email, password) => {
    Post(CAPLEAD_ONBOARDING_LOGIN_URL, {
      email,
      password,
    })
      .then(response => {
        if (response.status.type === 'Success') {
          const token = response.data.token;
          const username = response.name;
          const message = response.status.message;
          document.cookie = `token=${token}; expires=${new Date(
            Date.now() + 30 * 24 * 60 * 60 * 1000
          ).toUTCString()}; path=/`;
          document.cookie = `username=${username}; expires=${new Date(
            Date.now() + 30 * 24 * 60 * 60 * 1000
          ).toUTCString()}; path=/`;
          setUserInformation(token);
          setUsername(username);
          showSuccessMessage(message);
        } else {
          showErrorMessage(response.status.message);
        }
      })
      .catch(error => {
        throw error;
      });
  };
  

  const signOut = () => {
    const header = {'x-access-token': tokendata};
    Post(CAPLEAD_ONBOARDING_LOGOUT_URL, {}, header)
      .then(response => {
        if (response.status.type === 'Success') {
          document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;
          document.cookie =
            'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          setUsername(null);
          setUserInformation(null);
          setTokendata(null);
        } else {
          throw new Error('Logout Failed');
        }
      })
      .catch(error => {
        throw error;
      });
  };

  return (
    <LoginContext.Provider
      value={{signIn, signOut, loading, getCookie, username, user, tokendata}}>
      {children}
    </LoginContext.Provider>
  );
};

export default LoginProvider;
