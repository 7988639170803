import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {LoginContext} from '../userLogin/loginProvider';
import {Get} from '../../Axios';
import {CAPLEAD_ONBOARDING_GET_SUBSCRIBERS_LIST_URL} from '../../Api';
import {AlertMessageContext} from '../alertMessage/alertmessageProvider';

export const SubscriptionListContext = createContext();

const SubscriberProvider = ({children}) => {
  const {tokendata} = useContext(LoginContext);
  const [subList, setSubList] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState([]);
  const {showErrorMessage} = useContext(AlertMessageContext);

  const getSubscriberList = useCallback(() => {
    try {
      const headers = {'x-access-token': tokendata};
      Get(CAPLEAD_ONBOARDING_GET_SUBSCRIBERS_LIST_URL, headers)
        .then(response => {
          setSubList(response.data.data.companies);
          setTotalDataCount(response.data.data.totalItems);
        })
        .catch(error => {
          showErrorMessage('Something went wrong while fetching subscribers.');
        });
    } catch (error) {
      showErrorMessage('Something went wrong while fetching subscribers.');
    }
  }, []);

  
  useEffect(() => {
    getSubscriberList();
  }, []);

  return (
    <SubscriptionListContext.Provider value={{ subList, getSubscriberList,totalDataCount }}>
      {children}
    </SubscriptionListContext.Provider>
  );
};

export default SubscriberProvider;
