import {createTheme} from '@mui/material/styles';
import {colors} from '../lib/Utils/stringsUtils';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          textAlign: 'left',
          fontFamily: 'Lato, sans-serif, Arial',
          fontSize: 16,
          fontWeight: 400,
          color: colors.light_grey,
          '&:focus': {
            color: colors.primaryColor,
          },
          '&.Mui-focused': {
            color: colors.primaryColor,
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '.MuiTypography-root': {
            fontWeight: '700!important',
            fontSize: '20px',
            '@media (max-width:400px)': {
              fontSize: 16,
              lineHeight: '20px',
            },
          },
          '.MuiButtonBase-root': {
            padding: '0 9px',
          },
          '.MuiSvgIcon-root': {
            fill: colors.secondaryColor,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato, sans-serif, Arial',
          fontSize: 16,
          fontWeight: 400,
          '.MuiInputBase-input': {
            padding: '14px',
            height: 'auto',
          },
          '&:focus': {
            borderWidth: 2,
            borderColor: colors.primaryColor,
          },
          '&.Mui-focused': {
            borderWidth: 2,
            borderColor: colors.primaryColor,
          },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.primaryColor,
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
            {
              borderColor: colors.primaryColor,
            },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primaryColor,
          fontSize: 16,
          color: colors.white,
          fontWeight: 700,
          textTransform: 'capitalize',
          padding: '8px 22px ',
          lineHeight: '24px',
          letterSpacing: 0,
          fontFamily: 'Lato, sans-serif, Arial',
          '&:hover': {
            boxShadow: 'none',
            background: `${colors.primaryColor}`,
          },
          '&:focus': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          background: colors.white,
          padding: '16px 0',
          boxShadow: '0px 5px 10px rgba(32, 38, 36, 0.05)',
          position: 'sticky',
          top: '0',
          zIndex: 99,
          '&.MuiPaper-elevation4': {
            backgroundColor: '#fff',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato, sans-serif, Arial',
          color: colors.secondaryColor,
          letterSpacing: '0',
          fontWeight: 400,
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          padding: '0!important',
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: 'initial!important',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '.MuiButtonBase-root': {
            padding: '12px 20px',
          },
          '.MuiListItemIcon-root': {
            minWidth: '35px',
            color: colors.secondaryColor,
          },
          '.MuiListItemText-root': {
            margin: 0,
          },
        },
      },
    },

    MuiAvatar: {
      styleOverrides: {
        root: {
          '@media (max-width:620px)': {
            width: '30px',
            height: '30px',
          },
        },
      },
    },

    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: '50px',
          '@media (max-width:620px)': {
            padding: '20px',
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            fontSize: 18,
            padding: '20px 0 0 0',
            borderBottom: 'none',
            letterSpacing: 0,
            fontFamily: 'Lato, sans-serif, Arial',
            fontWeight: 700,
            lineHeight: '27px',
            color: `${colors.secondaryColor}`,
            '@media (max-width:620px)': {
              fontSize: 14,
              lineHeight: '20px',
            },
          },
          '& .MuiSvgIcon-root': {
            width: '20px',
            height: '20px',
          },
        },
      },
    },

    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            fontSize: 16,
            padding: '15px 0 0 0',
            borderBottom: 'none',
            letterSpacing: 0,
            fontFamily: 'Lato, sans-serif, Arial',
            lineHeight: '24px',
            '@media (max-width:620px)': {
              fontSize: 14,
              lineHeight: '20px',
            },
          },
          '& .MuiButtonBase-root': {
            padding: 0,
            fontSize: 16,
            fontWeight: 400,
          },
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          margin: '20px 0 40px 0',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          '@media (max-width:767px)': {
            justifyContent: 'center',
          },
          '& .MuiTypography-root': {
            fontSize: 16,
          },
          '& .MuiPagination-root': {
            margin: 0,
          },
          '& .MuiButtonBase-root': {
            fontWeight: '400!important',
            fontSize: 16,
            lineHeight: '24px',
            width: 40,
            height: 40,
            borderRadius: '50%',
            '@media (max-width:620px)': {
              fontSize: 12,
              lineHeight: '20px',
              width: '25px',
              height: '25px',
              minWidth: 'initial',
            },
          },
          '& .MuiButtonBase-root:hover': {
            background: colors.primaryColor,
            color: colors.white,
          },
          '& .MuiButtonBase-root.Mui-selected': {
            background: colors.primaryColor,
            color: colors.white,
          },
          '& .MuiButtonBase-root.Mui-selected:hover': {
            background: colors.primaryColor,
            color: colors.white,
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontWeight: '600!important',
      fontSize: 18,
      lineHeight: '22px',
      '@media (max-width:400px)': {
        fontSize: 14,
      },
    },
    h2: {
      fontWeight: '700!important',
      fontSize: 22,
      lineHeight: '33px',
      '@media (max-width:500px)': {
        fontSize: 20,
        lineHeight: '30px',
      },
    },
    h3: {
      fontWeight: '700!important',
      fontSize: 32,
      lineHeight: '48px',
      '@media (max-width:620px)': {
        fontSize: 25,
        lineHeight: '35px',
      },
    },
    h4: {
      fontWeight: '700!important',
      fontSize: 23,
      lineHeight: '42px',
      color: `${colors.blue}!important`,
      '@media (max-width:850px)': {
        fontSize: 18,
        lineHeight: '35px',
      },
      '@media (max-width:700px)': {
        fontSize: 15,
        lineHeight: '22px',
      },
    },
    h5: {
      fontWeight: '500!important',
      fontSize: 14,
      lineHeight: '21px',
      color: `${colors.white}!important`,
      background: colors.primaryColor,
      borderRadius: '70px',
      padding: '6px 10px',
    },
    h6: {
      fontSize: 18,
      lineHeight: '30px',
      '@media (max-width:620px)': {
        lineHeight: '20px',
      },
    },

    subtitle1: {
      fontWeight: '700!important',
      color: `${colors.blue}!important`,
      fontSize: 42,
      lineHeight: '55px',
      textAlign: 'center',
      '@media (max-width:620px)': {
        fontSize: 32,
        lineHeight: '38px',
      },
    },
    subtitle2: {
      fontWeight: '700!important',
      fontSize: 28,
      lineHeight: '42px',
      '@media (max-width:850px)': {
        fontSize: 24,
        lineHeight: '35px',
      },
      '@media (max-width:700px)': {
        fontSize: 19,
        lineHeight: '30px',
      },
    },

    body1: {
      fontSize: 16,
      lineHeight: '24px',
      fontFamily: 'Lato, sans-serif, Arial',
      '@media (max-width:620px)': {
        fontSize: 14,
        lineHeight: '20px',
      },
    },
    body2: {
      fontSize: 22,
      lineHeight: '33px',
      fontWeight: '500!important',
      margin: '4px 0',
      '@media (max-width:620px)': {
        fontSize: 18,
        lineHeight: '27px',
      },
    },
    caption: {
      fontSize: 14,
      lineHeight: '21px',
    },
  },
});
