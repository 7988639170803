import React, { createContext, useState } from "react"
export const AlertMessageContext = createContext()

const AlertMessageProvider = ({ children }) => {
  const [show, setShow] = useState(false)

  const [message, setMessage] = useState({ text: "", type: "" })

  const handleClose = () => {
    setShow(false)
  }
  const showErrorMessage = (message) => {
    setShow(true)
    setMessage({ text: message, type: "error" })
  }
  const showSuccessMessage = (message) => {
    setShow(true)
    setMessage({ text: message, type: "success" })
  }
  return (
    <AlertMessageContext.Provider
      value={{
        show,
        setShow,
        message,
        setMessage,
        handleClose,
        showErrorMessage,
        showSuccessMessage
      }}
    >
      {children}
    </AlertMessageContext.Provider>
  )
}

export default AlertMessageProvider
