import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import React, {useContext, useState} from 'react';
import Logo from '../../components/Icons/Logo';
import {LoginContext} from '../../services/Context/userLogin/loginProvider';
import './style.css';
import AlertMessage from '../../components/AlertMessage';
import {IconButton, InputAdornment} from '@material-ui/core';
import {colors} from '../../lib/Utils/stringsUtils';
import {Visibility, VisibilityOff} from '@mui/icons-material';
export default function Login() {
  const {signIn} = useContext(LoginContext);
  const [loading, setLoading] = useState();
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  });

  const [error, setError] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setPassword] = useState(false);
  const clearError = () => {
    setError({email: '', password: ''});
  };

  const onClickSignIn = async event => {
    event.preventDefault();
    const {email, password} = loginData;
    setLoading(true);
    if (email.trim() === '') {
      setError(prevError => ({
        ...prevError,
        email: 'Email is required',
      }));
    } else if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setError(prevError => ({
        ...prevError,
        email: 'Please enter a valid email',
      }));
    } else if (password.trim() === '') {
      setError(prevError => ({
        ...prevError,
        password: 'Password is required',
      }));
    } else {
      await signIn(email, password);
    }
    setLoading(false);
  };

  const inputHandler = evt => {
    clearError();
    const {name, value} = evt.target;
    setLoginData(prevLoginData => ({
      ...prevLoginData,
      [name]: value,
    }));
  };

  return (
    <Box className="loginbox-main flex justify-content align-item">
      <div className="container">
        <Box className="loginbox flex flex-column gap30">
          <Box className="logo-title flex flex-column align-item gap20">
            <Logo />
            <Typography variant="h1">
              Please enter your login credentials
            </Typography>
          </Box>
          <Box className="flex flex-column gap20">
            <TextField
              fullWidth
              label="Email Id"
              variant="outlined"
              name="email"
              value={loginData.email}
              onChange={inputHandler}
              error={Boolean(error.email)}
              helperText={error.email}
              autoComplete="email"
            />
            <TextField
              fullWidth
              label="Enter password"
              variant="outlined"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={loginData.password}
              onChange={inputHandler}
              error={Boolean(error.password)}
              helperText={error.password}
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        color: colors.primaryColor,
                        margin: '0px 0px 0px 10px',
                      }}
                      onClick={() => setPassword(!showPassword)}
                      edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              fullWidth
              type="submit"
              variant="contained"
              disabled={loading}
              onClick={onClickSignIn}
              style={{marginTop: '10px'}}
              className="loginnow">
              {loading ? <CircularProgress size={28} /> : 'Login'}
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
}
