import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

export default function UserInformationModal({
  showuserDetails,
  handleCloseviewModal,
  data,
}) {
  if (!data ) {
    return null;
  }
  return (
    <Dialog
      open={showuserDetails}
      onClose={handleCloseviewModal}
      PaperProps={{
        sx: {
          width: {xs: '100%', md: '100%'},
          maxWidth: '1100px',
          margin: '0 20px',
          height: '80vh',
        },
      }}>
      <IconButton
        aria-label="close"
        onClick={handleCloseviewModal}
        sx={{
          position: 'absolute',
          right: 20,
          top: 20,
          color: theme => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{padding: '20px'}} className="userdatashow">
        <Box className="invoiceheader flex align-item justify-between">
          <Typography variant="h4">Company Details</Typography>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Industry</TableCell>
              <TableCell>Sub Industry</TableCell>
              <TableCell>Gst No</TableCell>
              <TableCell sx={{width: '280px'}}>Address</TableCell>
              <TableCell sx={{width: '40px'}}>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
              <TableRow>
                <TableCell>{data.invoiceNumber}</TableCell>
                <TableCell>{data.name}</TableCell>
                <TableCell>{data.industry}</TableCell>
                <TableCell>{data.subIndustry}</TableCell>
                <TableCell>{data.gstNo}</TableCell>
                <TableCell>{data.address}</TableCell>
                <TableCell>
                  {new Date(data.createdAt).toLocaleDateString('en-GB')}
                </TableCell>
              </TableRow>
          </TableBody>
        </Table>
        <hr
          style={{
            marginTop: '30px',
            marginBottom: '20px',
            border: 'solid 1px #f5f5f5',
          }}></hr>
        <Box className="invoiceheader flex align-item justify-between">
          <Typography variant="h4">User Details</Typography>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell sx={{width: '260px'}}>Designation</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell sx={{width: '44px'}}>Gender</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow>
                <TableCell>{data.vcCapUsers[0].name}</TableCell>
                <TableCell>{data.vcCapUsers[0].designation}</TableCell>
                <TableCell>{data.vcCapUsers[0].email}</TableCell>
                <TableCell>{data.vcCapUsers[0].mobileNumber}</TableCell>
                <TableCell>{data.vcCapUsers[0].gender}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
        <hr
          style={{
            marginTop: '30px',
            marginBottom: '20px',
            border: 'solid 1px #f5f5f5',
          }}></hr>
        <Box className="invoiceheader flex align-item justify-between">
          <Typography variant="h4">Plans Details</Typography>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Plan Name</TableCell>
              <TableCell>Scans Left</TableCell>
              <TableCell>Total Scans</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
              <TableRow>
                <TableCell>{data.vcPlanDetails[0].planName}</TableCell>
                <TableCell>{data.vcPlanDetails[0].scansLeft}</TableCell>
                <TableCell>{data.vcPlanDetails[0].totalScans}</TableCell>
              </TableRow>
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}
