import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {LoginContext} from '../userLogin/loginProvider';
import {AlertMessageContext} from '../alertMessage/alertmessageProvider';
import {Get, Post} from '../../Axios';
import {
  CAPLEAD_ONBOARDING_GET_PLANS_URL,
  CAPLEAD_ONBOARDING_REGISTER_COMPANY_URL,
} from '../../Api';

export const UserRegistration = createContext();

const RegistrationProvider = ({children}) => {
  const [plans, setPlans] = useState([]);
  const {tokendata} = useContext(LoginContext);
  const {showErrorMessage, showSuccessMessage} =
    useContext(AlertMessageContext);

  const addSubscriber = async data => {
    try {
      const headers = {
        'x-access-token': tokendata,
      };
      const response = await Post(
        CAPLEAD_ONBOARDING_REGISTER_COMPANY_URL,
        data,
        headers,
      );
      if (response.status.code === 201 || response.status.code === 200) {
        showSuccessMessage(response.status.message);
      } else {
        showErrorMessage(response.status.message);
      }
      return response;
      
    } catch {
      showErrorMessage('Something went wrong while registering company.');
    }
  };

  const getSubscriptionPlans = useCallback(() => {
    try {
      const headers = {'x-access-token': tokendata};
      Get(CAPLEAD_ONBOARDING_GET_PLANS_URL, headers)
        .then(response => {
          setPlans(response.data);
        })
        .catch(error => {
          showErrorMessage('Something went wrong while fetching plans.');
        });
    } catch {
      showErrorMessage('Something went wrong while fetching plans.');
    }
  }, []);

  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  return (
    <UserRegistration.Provider
      value={{
        addSubscriber,
        getSubscriptionPlans,
        plans,
      }}>
      {children}
    </UserRegistration.Provider>
  );
};

export default RegistrationProvider;
