import React from 'react';
import './style.css';
import Logo from '../components/Icons/Logo';
export default function Splash() {
  return (
    <div className="splash-screen">
      <Logo />
    </div>
  );
}
