import {ThemeProvider} from '@emotion/react';
import Navigation from './navigation';
import {theme} from './theme';
import AlertMessageProvider from './services/Context/alertMessage/alertmessageProvider';
import LoginProvider from './services/Context/userLogin/loginProvider';
import { useEffect, useState } from 'react';
import Splash from './splash';
import AlertMessage from './components/AlertMessage';

function App() {
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setShowSplashScreen(false);
    }, 1000);
  }, []);
  return (
    <div className="App">
      {showSplashScreen ? (
        <Splash />
      ) : (
        <ThemeProvider theme={theme}>
          <AlertMessageProvider>
            <LoginProvider>
              <AlertMessage />
              <Navigation />
            </LoginProvider>
          </AlertMessageProvider>
        </ThemeProvider>
      )}
    </div>
  );
}
export default App;
