import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const Get = async (endpoint, headers) => {
  try {
    const response = await api.get(endpoint, {headers});
    return response;
  } catch (error) {
    throw error;
  }
};

export const Post = async (endpoint, data, headers) => {
  try {
    const response = await api.post(endpoint, data, { headers });

    if (response && response.data) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

export const Update = async (endpoint, data, headers) => {
  try {
    const response = await api.post(endpoint, data, {headers});
    if (response && response.data) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

export const Delete = async (endpoint, headers) => {
  try {
    const response = await api.delete(endpoint, {headers});
    if (response && response.data) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};
