import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import PagenotFound from '../components/PageNotFound';
import RegistrationForm from '../pages/CardManual';
import CardScan from '../pages/CardScan';
import LoginPage from '../pages/Login';
import SubscribersList from '../pages/Subscribers';
import SubscriptionPlans from '../pages/SubscriptionPlans';
import SubscriberProvider from '../services/Context/subscriberList/subscriberProvider';
import {
  LoginContext,
} from '../services/Context/userLogin/loginProvider';
import UserRegistrationProvider from '../services/Context/userRegistration/registrationProvider';
import Splash from '../splash';
export default function Navigation() {
  const {tokendata, loading} = useContext(LoginContext);
  if (loading) {
    return <Splash />;
  }
  if (tokendata) {
    return (
      <Routes>
        <Route path="/" element={<RegistrationForm />} />
        <Route path="/card-scan" element={<CardScan />} />
        <Route
          path="/subscription-plans"
          element={
            <UserRegistrationProvider>
              <SubscriptionPlans />
            </UserRegistrationProvider>
          }
        />
        <Route
          path="/subscribers-list"
          element={
            <SubscriberProvider>
              <SubscribersList />
            </SubscriberProvider>
          }
        />
        <Route path="*" element={<PagenotFound />}></Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="*" element={<PagenotFound />}></Route>
      <Route path="/" element={<LoginPage />} />
    </Routes>
  );
}
