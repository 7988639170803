import {ArrowBack, BorderColor, ReceiptLong} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
} from '@mui/material';
import React, {Fragment, useState} from 'react';
import Logo from '../Icons/Logo';
import {Link} from 'react-router-dom';

export default function LeftDrawer({innerPage}) {
  const [state, setState] = useState({left: false});
  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({...state, [anchor]: open});
  };

  function getPageLink(text) {
    switch (text) {
      case 'Onboarding':
        return '/';
      case 'User list':
        return '/subscribers-list';
      default:
        return '#';
    }
  }
  const list = anchor => (
    <Box
      sx={{
        width: {
          xs: anchor === 'top' || anchor === 'bottom' ? 'auto' : 180,
          sm: anchor === 'top' || anchor === 'bottom' ? 'auto' : 200,
          md: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250,
        },
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <Box className="logosidebar">
        <Logo />
      </Box>
      <List>
        {['Onboarding', 'User list'].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton component={Link} to={getPageLink(text)}>
              <ListItemIcon>
                {index % 2 === 0 ? <BorderColor /> : <ReceiptLong />}
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const goBack = () => {
    window.history.back();
  };
  return (
    <div className="flet-menubar">
      {['left'].map(anchor => (
        <Fragment key={anchor}>
          {innerPage ? (
            <IconButton onClick={goBack} disableRipple>
              <ArrowBack />
            </IconButton>
          ) : (
            <IconButton onClick={toggleDrawer(anchor, true)} disableRipple>
              <MenuIcon />
            </IconButton>
          )}

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}>
            {list(anchor)}
          </SwipeableDrawer>
        </Fragment>
      ))}
    </div>
  );
}
