import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import ConfirmModal from '../../components/ConfirmModal';
import Header from '../../components/Header';
import './style.css';
import { AlertMessageContext } from '../../services/Context/alertMessage/alertmessageProvider';
import { Link, useLocation } from 'react-router-dom';
import { CenterFocusWeak } from '@mui/icons-material';

export default function RegistrationForm() {
  const location = useLocation();
  const cardData = location?.state?.analyzingData;
  const [showGSTNumber, setShowGSTNumber] = useState(false);
  const handleCheckboxChange = event => {
    setShowGSTNumber(event.target.checked);
  };
  const { showErrorMessage } = useContext(AlertMessageContext);
  const [confirm, setConfirm] = useState(false);
  const [formData, setFormData] = useState({
    name: cardData ? cardData?.name : '',
    email: cardData ? cardData?.email : '',
    mobileNumber: cardData ? cardData?.mobileNumber : '',
    designation: cardData ? cardData?.designation : '',
    companyName: cardData ? cardData?.companyName : '',
    address: cardData ? cardData?.address : '',
    gstNumber: '',
  });

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleClickOpen = () => {
    const allFieldsFilled = [
      'name',
      'email',
      'mobileNumber',
      'designation',
      'companyName',
      'address',
    ].every(field => formData[field] !== '' && field !== 'gstNumber');

    if (!allFieldsFilled) {
      showErrorMessage('Please fill all the fields.');
      return;
    }
    setConfirm(true);
  };

  const handleClose = () => {
    setConfirm(false);
  };

  return (
    <Box className="RegistrationForm">
      <Header />
      <Box className="container">
        <Paper className="mt40 mb40 registration-borderbg" elevation={0}>
          <Typography variant="h2">Registration form</Typography>
          <Box className="registration-fill flex gap20 flex-column">
            <Box className="flex flex-flow gap20 justify-between">
              <Box className="fullbox">
                <TextField
                  label="Name"
                  variant="outlined"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </Box>
              <Box className="fullbox">
                <TextField
                  label="Email"
                  variant="outlined"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Box>
            </Box>
            <Box className="flex flex-flow gap20 justify-between">
              <Box className="fullbox">
                <TextField
                  label="Number"
                  variant="outlined"
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={handleChange}
                />
              </Box>
              <Box className="fullbox">
                <TextField
                  label="Designation"
                  variant="outlined"
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange}
                />
              </Box>
            </Box>
            <Box>
              <TextField
                label="Company name"
                name="companyName"
                variant="outlined"
                value={formData.companyName}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <TextField
                label="Address"
                name="address"
                variant="outlined"
                address="address"
                value={formData.address}
                onChange={handleChange}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={<Checkbox onChange={handleCheckboxChange} />}
                label="I want to add GST number (Optional)"
              />
            </Box>
            {showGSTNumber && (
              <Box>
                <TextField
                  label="GST number"
                  variant="outlined"
                  name="gstNumber"
                  value={formData.gstNumber}
                  onChange={handleChange}
                />
              </Box>
            )}
            <Button
              onClick={handleClickOpen}
              style={{ marginTop: '30px' }}
              fullWidth>
              Next
            </Button>
          </Box>
        </Paper>

        {/* <Box className="open-camera mb40">
          <Typography>or</Typography>
          <Box
            component={Link}
            to="/card-scan"
            className="camera-box flex align-item flex-column">
            <CenterFocusWeak />
            <Typography>Scan data</Typography>
          </Box>
        </Box> */}
      </Box>
      <ConfirmModal
        openModal={confirm}
        closeModal={handleClose}
        formData={formData}
      />
    </Box>
  );
}
