import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
export default function ConfirmModal({openModal, closeModal, formData}) {
  return (
    <Dialog
      open={openModal}
      onClose={closeModal}
      PaperProps={{
        sx: {width: {xs: '100%', md: '593px'}, margin: {xs: '0 20px', md: '0'}},
      }}>
      <DialogContent>
        <Typography variant="h3">Confirm your details</Typography>
        <Box className="registrationdetailsmodal-view gap20 flex flex-column">
          <Box className="labalbox-main flex justify-between gap20">
            <Box className="labal-box flex flex-column">
              <label>Name</label>
              <Typography variant="h6">{formData.name}</Typography>
            </Box>
            <Box className="labal-box">
              <label>Email</label>
              <Typography variant="h6">{formData.email}</Typography>
            </Box>
          </Box>
          <Box className="labalbox-main flex justify-between gap20">
            <Box className="labal-box flex flex-column">
              <label>Mobile number</label>
              <Typography variant="h6">{formData.mobileNumber}</Typography>
            </Box>
            <Box className="labal-box">
              <label>Designation</label>
              <Typography variant="h6">{formData.designation}</Typography>
            </Box>
          </Box>
          <Box className="labalbox-main flex justify-between gap20">
            <Box className="labal-box flex flex-column">
              <label>Company</label>
              <Typography variant="h6">{formData.companyName}</Typography>
            </Box>
            {/* <Box className="labal-box">
              <label>Company name</label>
              <Typography variant="h6">Dedik</Typography>
            </Box> */}
          </Box>
        </Box>
        <Button
          fullWidth
          onClick={closeModal}
          component={Link}
          state={formData}
          to={'/subscription-plans'}>
          Confirm
        </Button>
      </DialogContent>
    </Dialog>
  );
}
