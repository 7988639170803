import React, {useContext, useState} from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import Header from '../../components/Header';
import UserInformationModal from '../../components/UserInformationModal';
import {SubscriptionListContext} from '../../services/Context/subscriberList/subscriberProvider';
import './style.css';

export default function SubscribersList() {
  const [showuserDetails, setShowuserDetails] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const {subList} = useContext(SubscriptionListContext);
  const totalData = subList.length;

  const handleClickOpenviewModal = (index) => {
    setSelectedRowData(subList[index]);
    setShowuserDetails(true);
  };
  const handleCloseviewModal = () => {
    setShowuserDetails(false);
  };
  return (
    <Box className="subscribersList">
      <Header />
      <Box className="container">
        <Box>
          <p>Total Data: {totalData}</p>
        </Box>

        <TableContainer sx={{maxHeight: '80vh'}} className="table-main">
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Person name</TableCell>
                <TableCell>Company</TableCell>
                <TableCell>Email</TableCell>
                <TableCell style={{width: '90px'}}>Date</TableCell>
                <TableCell style={{width: '10px'}}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subList.map((list, index) => {
                const personName = list.vcCapUsers?.[0]?.name || '';
                const userEmail = list.vcCapUsers?.[0]?.email || '';
                return (
                  <TableRow key={index}>
                    <TableCell>{personName}</TableCell>
                    <TableCell>{list.name}</TableCell>
                    <TableCell>{userEmail}</TableCell>
                    <TableCell style={{width: '96px'}}>
                      {new Date(list.updatedAt).toLocaleDateString('en-GB')}
                    </TableCell>
                    <TableCell>
                      <Tooltip title="View More">
                        <IconButton onClick={handleClickOpenviewModal.bind(null, index)}>
                          <RemoveRedEyeIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <UserInformationModal
        data={selectedRowData}
        showuserDetails={showuserDetails}
        handleCloseviewModal={handleCloseviewModal}
      />
    </Box>
  );
}
