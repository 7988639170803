import {
  AppBar,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import {Box} from '@mui/system';
import React, {useContext, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {LoginContext} from '../../services/Context/userLogin/loginProvider';
import Logo from '../Icons/Logo';
import LeftDrawer from './Sidebar';
import AlertDialog from './logout';
import './style.css';

export default function Header({innerPage}) {
  const [anchorElUser, setAnchorElUser] = useState();
  const {username} = useContext(LoginContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleDialog = () => {
    setOpen(true);
  };

  const handleDropDown = dropDown => {
    switch (dropDown) {
      case 'Dashboard':
        navigate('/');
        break;
      case 'Log Out':
        handleDialog();
        break;
      default:
        break;
    }
    handleCloseUserMenu();
  };

  const name = username || '';
  const avtarname = name
    .split(' ')
    .map(n => n[0])
    .join('');

  return (
    <AppBar position="static" className="header">
      <div className="container">
        <Toolbar>
          <Box className="flex menubutton align-item">
            <LeftDrawer innerPage={innerPage ? innerPage : false} />
            <Box component={Link} to={'/'} className="logo flex align-item">
              <Logo />
            </Box>
          </Box>

          <Box>
            <Box className="usermenu" onClick={handleOpenUserMenu}>
              <Avatar>{avtarname}</Avatar>
              <IconButton disableRipple sx={{p: 0}}>
                <Typography variant="body1">{name}</Typography>
              </IconButton>
            </Box>
            <Menu
              sx={{mt: '45px'}}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {['Dashboard', 'Log Out'].map(dropDown => (
                <MenuItem
                  key={dropDown}
                  onClick={() => handleDropDown(dropDown)}>
                  {dropDown}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
        <AlertDialog open={open} close={() => setOpen(false)} />
      </div>
    </AppBar>
  );
}
