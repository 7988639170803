import React from 'react';

export default function Logo() {
  return (
    <svg
      width="192"
      height="50"
      viewBox="0 0 192 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.9559 15.9736L17.9514 20.7943C16.6608 19.4991 15.1241 18.8519 13.3414 18.8528C12.5082 18.8506 11.6827 19.0081 10.9124 19.3162C10.1422 19.6243 9.44236 20.0769 8.85317 20.6481C8.26398 21.2193 7.79706 21.8978 7.47926 22.6445C7.16146 23.3913 6.99903 24.1915 7.00133 24.9993C6.99903 25.8071 7.16146 26.6074 7.47926 27.3541C7.79706 28.1008 8.26398 28.7793 8.85317 29.3505C9.44236 29.9217 10.1422 30.3744 10.9124 30.6825C11.6827 30.9906 12.5082 31.148 13.3414 31.1458C15.1202 31.1458 16.655 30.4897 17.9456 29.1776L22.9225 34.0222C20.2311 36.6315 17.0407 37.9362 13.3515 37.9362C9.66236 37.9362 6.51311 36.6756 3.90381 34.1544C1.30127 31.6294 0 28.5777 0 24.9993C0 21.4209 1.30127 18.3692 3.90381 15.8443C6.50731 13.3221 9.65317 12.0606 13.3414 12.0596C17.0151 12.0596 20.2199 13.3643 22.9559 15.9736Z"
        fill="#494A4C"
      />
      <path
        d="M51.3847 37.2923H44.3746V35.6053C42.9728 37.1583 40.6371 37.9348 37.3675 37.9348C33.7189 37.9348 30.6934 36.684 28.291 34.1825C25.8886 31.681 24.6874 28.6185 24.6874 24.9951C24.6874 21.3932 25.9108 18.3364 28.3577 15.8246C30.8046 13.3127 33.8088 12.0578 37.3704 12.0596C40.5955 12.0596 42.9312 12.8469 44.3775 14.4215V12.7077H51.3847V37.2923ZM44.3746 24.9993C44.3732 23.7837 44.0004 22.5958 43.3033 21.5853C42.6062 20.5749 41.616 19.7873 40.4577 19.3218C39.2994 18.8563 38.0248 18.7339 36.7948 18.97C35.5648 19.206 34.4344 19.79 33.5464 20.6481C32.3602 21.8043 31.6944 23.3687 31.6944 24.9993C31.6944 26.63 32.3602 28.1943 33.5464 29.3505C34.4335 30.2125 35.5648 30.7997 36.7966 31.0375C38.0285 31.2752 39.3055 31.1529 40.4656 30.686C41.6256 30.2191 42.6165 29.4286 43.3124 28.415C44.0084 27.4013 44.3781 26.21 44.3746 24.9923V24.9993Z"
        fill="#494A4C"
      />
      <path
        d="M121.756 24.9993C121.761 26.1478 121.604 27.2916 121.289 28.3987H103.106C104.373 30.232 106.141 31.1486 108.41 31.1486C110.189 31.1486 111.724 30.4926 113.015 29.1804L118.019 34.0222C115.283 36.6315 112.08 37.9362 108.409 37.9362C104.715 37.9362 101.567 36.6747 98.9656 34.1516C96.364 31.6285 95.0627 28.5768 95.0618 24.9965C95.0618 21.4172 96.363 18.3655 98.9656 15.8414C101.568 13.3174 104.716 12.0559 108.409 12.0568C112.101 12.0568 115.249 13.3184 117.852 15.8414C120.456 18.3645 121.757 21.4172 121.756 24.9993ZM113.68 21.6027C112.458 19.7695 110.701 18.8528 108.409 18.8528C106.139 18.8528 104.371 19.7695 103.104 21.6027H113.68Z"
        fill="#04B983"
      />
      <path
        d="M151.819 37.2923H144.812V35.6053C143.409 37.1583 141.074 37.9348 137.805 37.9348C134.156 37.9348 131.131 36.684 128.728 34.1825C126.326 31.681 125.125 28.6185 125.125 24.9951C125.125 21.3933 126.349 18.3364 128.797 15.8246C131.245 13.3127 134.247 12.0564 137.805 12.0554C141.03 12.0554 143.366 12.8427 144.812 14.4173V12.7077H151.819V37.2923ZM144.812 24.9993C144.809 23.9896 144.55 22.996 144.057 22.1066C143.564 21.2171 142.852 20.4591 141.985 19.8996C141.118 19.3401 140.122 18.9963 139.085 18.8986C138.049 18.801 137.003 18.9524 136.041 19.3396C135.079 19.7268 134.23 20.3378 133.57 21.1185C132.909 21.8993 132.457 22.8258 132.254 23.8162C132.051 24.8065 132.102 25.8302 132.404 26.7967C132.706 27.7632 133.248 28.6427 133.984 29.3575C134.871 30.2196 136.002 30.8067 137.234 31.0445C138.466 31.2823 139.743 31.1599 140.903 30.693C142.063 30.2261 143.054 29.4357 143.75 28.422C144.446 27.4083 144.816 26.217 144.812 24.9993Z"
        fill="#04B983"
      />
      <path
        d="M174.842 0V13.5794C172.889 12.5531 170.703 12.0171 168.481 12.0202C168.11 12.0202 167.739 12.0343 167.375 12.0666C164.014 12.1707 161.162 13.4247 158.819 15.8288C157.077 17.5855 155.897 19.7934 155.42 22.1861C155.212 23.1036 155.107 24.0403 155.108 24.9796C155.107 25.7433 155.177 26.5056 155.317 27.2571C155.316 27.2622 155.316 27.2675 155.317 27.2726C155.694 29.8662 156.893 32.282 158.75 34.1853C161.153 36.6896 164.178 37.9409 167.826 37.939C167.926 37.939 168.028 37.939 168.128 37.939H168.173C168.271 37.939 168.373 37.939 168.473 37.939C170.229 37.9406 171.969 37.6066 173.592 36.9561C175.215 36.3055 176.69 35.3511 177.932 34.1475C179.174 32.9439 180.16 31.5147 180.832 29.9416C181.504 28.3685 181.85 26.6823 181.849 24.9796V2.71756L174.842 0ZM172.991 29.3505C172.104 30.2127 170.973 30.8001 169.741 31.0379C168.51 31.2758 167.233 31.1535 166.072 30.6866C164.912 30.2196 163.922 29.429 163.226 28.4152C162.53 27.4014 162.161 26.21 162.165 24.9923C162.168 23.9829 162.427 22.9898 162.921 22.1008C163.414 21.2118 164.125 20.4542 164.992 19.8951C165.859 19.3359 166.854 18.9924 167.891 18.8949C168.927 18.7974 169.972 18.9489 170.934 19.3359C171.895 19.723 172.744 20.3338 173.404 21.1143C174.064 21.8947 174.516 22.8209 174.72 23.8108C174.923 24.8008 174.872 25.8241 174.57 26.7902C174.269 27.7564 173.727 28.6357 172.991 29.3505Z"
        fill="#04B983"
      />
      <path
        d="M61.6459 50V36.4122C63.5993 37.4426 65.7883 37.9811 68.0121 37.9783C68.3848 37.9783 68.7546 37.9643 69.12 37.9319C72.4805 37.8279 75.33 36.5743 77.6686 34.1712C79.4091 32.4134 80.5881 30.2051 81.0634 27.8124C81.2461 26.8857 81.3364 25.9442 81.3332 25.0007C81.3341 24.2452 81.2789 23.4906 81.1679 22.7429C81.1693 22.7378 81.1693 22.7324 81.1679 22.7274C80.7877 20.1325 79.5858 17.716 77.7281 15.8119C75.3267 13.3094 72.3016 12.0587 68.6531 12.0596C68.5515 12.0596 68.4515 12.0596 68.35 12.0596C68.3351 12.0568 68.3199 12.0568 68.305 12.0596C68.2064 12.0596 68.1064 12.0596 68.0048 12.0596C66.249 12.0596 64.5103 12.395 62.8882 13.0466C61.2661 13.6983 59.7923 14.6534 58.5511 15.8574C57.3098 17.0614 56.3254 18.4907 55.6541 20.0636C54.9829 21.6366 54.6378 23.3223 54.6388 25.0246V47.2824L61.6459 50ZM63.4949 20.6495C64.3815 19.788 65.512 19.201 66.743 18.963C67.9741 18.725 69.2503 18.8466 70.41 19.3124C71.5697 19.7783 72.5607 20.5674 73.2573 21.5798C73.9539 22.5922 74.3249 23.7823 74.3231 24.9993C74.3211 26.0089 74.0625 27.0025 73.5703 27.8922C73.078 28.7819 72.3671 29.5403 71.5006 30.1003C70.6341 30.6604 69.6387 31.0048 68.6022 31.1032C67.5657 31.2016 66.5202 31.0509 65.5582 30.6644C64.5961 30.2779 63.747 29.6676 63.0861 28.8873C62.4251 28.1071 61.9727 27.181 61.7687 26.1909C61.5647 25.2009 61.6155 24.1773 61.9166 23.2108C62.2177 22.2443 62.7597 21.3646 63.4949 20.6495Z"
        fill="#494A4C"
      />
      <path
        d="M91.7249 37.939H84.7178V0.00701904L91.7249 2.72458V37.939Z"
        fill="#04B983"
      />
      <path
        d="M188.375 37.9334C190.377 37.9334 192 36.3598 192 34.4187C192 32.4776 190.377 30.904 188.375 30.904C186.372 30.904 184.749 32.4776 184.749 34.4187C184.749 36.3598 186.372 37.9334 188.375 37.9334Z"
        fill="#271E7B"
      />
    </svg>
  );
}
