import React from 'react'
import './style.css'

export default function PagenotFound() {
  
  return (
    <div className="container">
      <section className="error-main">
        <h1 className="title1">404 <strong style={{ color: "#04B983" }}>Page Not Found!</strong></h1>
        <p>
        The page you were looking for doesn't exist. You may have <strong>MISTYPED</strong> the address or the page may have moved.
        </p>
        <a href="/" className="btnhome">
          GO TO DASHBOARD
        </a>
      </section>
    </div>
  )
}
