import {Box, Button, Divider, Typography} from '@mui/material';
import React, {useContext, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Header from '../../components/Header';
import {UserRegistration} from '../../services/Context/userRegistration/registrationProvider';
import {Card_Data} from './plans';
import './style.css';
export default function SubscriptionPlans() {
  const {addSubscriber, plans} = useContext(UserRegistration);
  const [activeIndex, setActiveIndex] = useState(1);
  const [paymentCards, setPaymentCards] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState([activeIndex]);
  const {state} = useLocation();

  const handleClick = id => {
    const data = plans.data.find(plan => plan.id === id);
    let selectedPlan = {...data, subscriptionId: data.id};
    setSelectedPlan(selectedPlan);
    setActiveIndex(id);
  };

  const handleClickpayment = card => {
    setPaymentCards(card.title);
  };

  const [confirm, setConfirm] = useState(false);

  const handleClickOpen = () => {
    setConfirm(true);
    try {
      const selectedPaymentCard = paymentCards;
      const userDataWithPlan = {
        ...selectedPlan,
        ...state,
        modeOfPayment: selectedPaymentCard,
      };
      addSubscriber(userDataWithPlan);
      setTimeout(() => {
        setConfirm(false);
        // navigate('/payment');
      }, 3000);
    } catch (error) {}
  };

  const handleClose = () => {
    setConfirm(false);
  };

  const calculateGST = price => {
    return price * 0.18;
  };

  const subtotal =
    selectedPlan && selectedPlan.price
      ? Number(selectedPlan.price.replace('₹', '').toLocaleString())
      : 0;
  const tax = calculateGST(subtotal);
  const grandTotal = subtotal + tax;

  const formattedPrice =
    selectedPlan && selectedPlan.price
      ? `₹${Number(selectedPlan.price).toLocaleString()}`
      : '';

  return (
    <div>
      <Header innerPage={true} />
      <Box className="container">
        <Box className="select-plans mt40 mb40">
          <Typography variant="subtitle1">Choose a plan</Typography>
          <Box className="view-plans flex justify-between align-item mt40">
            {Array.isArray(plans.data) ? (
              plans.data.length > 0 ? (
                plans.data.map(plan => {
                  return (
                    <Box
                      onClick={() => handleClick(plan.id)}
                      className={`plansbox ${
                        activeIndex === plan.id ? 'active' : ''
                      }`}
                      key={plan.id}>
                      {plan.name === 'STANDARD PLAN' ? (
                        <Typography variant="h5">{'Most popular'}</Typography>
                      ) : null}
                      <Typography variant="h4">{plan.name}</Typography>
                      <Typography variant="body2">{plan.price}</Typography>
                      <Typography variant="caption">
                        {'(excluding tax)'}
                      </Typography>
                      <Divider />
                      <Typography variant="subtitle2">
                        {plan.scans} scans
                      </Typography>
                    </Box>
                  );
                })
              ) : (
                <Typography variant="body2">No plans available</Typography>
              )
            ) : (
              <Typography variant="body2">Loading plans...</Typography>
            )}
          </Box>
        </Box>

        <Box className="payment-method mb40">
          <Typography variant="h4">Payment method</Typography>
          <Box className="payment-method-main flex gap20">
            {Card_Data.paymentcard.map((card, index) => (
              <Box
                onClick={() => handleClickpayment(card)}
                className={`payment-box flex justify-content align-item flex-column ${
                  paymentCards === card.title ? 'addactive' : ''
                }`}
                key={index}>
                {card.icon}
                <Typography>{card.title}</Typography>
              </Box>
            ))}
          </Box>
        </Box>

        <Box className="breakdown mb40">
          <Typography variant="h4">Payment breakdown</Typography>
          <Box className="invoice-main flex justify-between">
            <Box className="invoicebox flex flex-column">
              <strong>Plan</strong>
              <span>{selectedPlan.name}</span>
            </Box>
            <Box className="invoicebox flex flex-column">
              <strong>Price</strong>
              <span>{formattedPrice}</span>
            </Box>
            <Box className="invoicebox flex flex-column">
              <strong>Amount</strong>
              <span>{formattedPrice}</span>
            </Box>
          </Box>

          <ul className="totaltex">
            <li>
              <span>Subtotal</span> <span>{formattedPrice}</span>
            </li>
            <li>
              <span>Tax (18%)</span> <span>{'₹' + tax.toLocaleString()}</span>
            </li>
            <li>
              <strong>Grand Total</strong>{' '}
              <strong>{'₹' + grandTotal.toLocaleString()}</strong>
            </li>
          </ul>
        </Box>

        <Button onClick={handleClickOpen} className="invoicesend-btn" fullWidth>
          Proceed to payment
        </Button>
      </Box>
    </div>
  );
}
