import { Button } from '@mui/base';
import { FlipCameraAndroid, Merge, NoPhotography } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import { Camera } from '../../components/Scan-data/camera';
import { Footer, GlobalStyle, Preview, Root } from '../../style';

const key = process.env.REACT_APP_AZURE_SUBCRIBER_KEY;
const endpoint = process.env.REACT_APP_AZURE_ENDPOINT;

export default function CardScan() {
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [frontImage, setFrontImage] = useState();
  const [backImage, setBackImage] = useState();
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const convertBlobToBase64 = blob => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  const onCapture = async blob => {
    if (!frontImage) {
      // Convert the front image Blob to base64
      const frontImageBase64 = await convertBlobToBase64(blob);
      setFrontImage(frontImageBase64);
      // setIsCameraOpen(false);
    } else {
      // Convert the back image Blob to base64
      const backImageBase64 = await convertBlobToBase64(blob);
      setBackImage(backImageBase64);
      setIsCameraOpen(false);
    }
  };

  const onClear = () => {
    if (frontImage && backImage) {
      setFrontImage(undefined);
      setBackImage(undefined);
    }
  };

  const mergeImages = () => {
    try {
      setLoader(true);
      if (frontImage && backImage) {
        const frontImageElement = new Image();
        frontImageElement.src = frontImage;
        frontImageElement.addEventListener('load', () => {
          const backImageElement = new Image();
          backImageElement.src = backImage;
          backImageElement.addEventListener('load', () => {
            const { x, y, width, height } = calculateMergeDimensions(
              frontImageElement,
              backImageElement,
            );

            const canvasWidth = Math.max(
              frontImageElement.width,
              backImageElement.width,
            );
            const canvasHeight =
              frontImageElement.height + backImageElement.height;

            // Ensure the canvas width and height meet the minimum requirement of 200x200
            const finalCanvasWidth = Math.max(canvasWidth, 440);
            const finalCanvasHeight = Math.max(canvasHeight, 600);

            const canvas = document.createElement('canvas');
            canvas.width = finalCanvasWidth;
            canvas.height = finalCanvasHeight;
            const context = canvas.getContext('2d');

            // Draw the front image at the top
            context.drawImage(
              frontImageElement,
              0,
              0,
              frontImageElement.width,
              frontImageElement.height,
            );

            // Draw the back image at the bottom
            context.drawImage(backImageElement, x, y, width, height);

            canvas.toBlob(blob => {
              const mergeImageFile = new File([blob], 'merge.jpg', {
                type: 'image/jpeg',
              });
              buisnessCardScanner(mergeImageFile);
            }, 'image/jpeg');
          });
        });
      } else {
        setError('Please upload both front and back images');
      }
    } catch (error) {
      setError('An error occurred during image merging: ' + error);
    } finally {
      setLoader(false);
    }
  };

  const calculateMergeDimensions = (frontImageElement, backImageElement) => {
    const canvasWidth = Math.max(
      frontImageElement.width,
      backImageElement.width,
    );
    const canvasHeight = frontImageElement.height + backImageElement.height;

    // Calculate the position and dimensions of the back image
    const backImageWidth = Math.min(canvasWidth, backImageElement.width);
    const backImageHeight = Math.min(
      canvasHeight - frontImageElement.height,
      backImageElement.height,
    );
    const x = (canvasWidth - backImageWidth) / 2; // Position the back image horizontally centered
    const y = frontImageElement.height; // Position the back image below the front image

    return { x, y, width: backImageWidth, height: backImageHeight };
  };




  const buisnessCardScanner = mergeArrayImage => {
    let analyzingData = {};
    setLoader(true);

    const headers = {
      'Content-Type': 'application/octet-stream',
      'Ocp-Apim-Subscription-Key': key,
      'Access-Control-Allow-Origin': '*',
    };

    axios
      .post(endpoint, mergeArrayImage, { headers })
      .then(postResponse => {
        // Delay for 8 to 10 seconds
        setTimeout(() => {
          axios
            .get(postResponse.headers['operation-location'], { headers: headers })
            .then(getResponse => {
              const { data } = getResponse;

              if (
                data &&
                data.analyzeResult &&
                data.analyzeResult.documentResults &&
                data.analyzeResult.documentResults.length > 0
              ) {
                const documentResult = data.analyzeResult.documentResults[0];
                const businessCard = documentResult.fields;

                const name = businessCard.ContactNames;
                const contactName = name?.valueArray[0].text;

                const designation = businessCard.JobTitles;
                const designationContent = designation?.valueArray[0].text;

                const company = businessCard.CompanyNames;
                const companyContent = company?.valueArray[0].text;

                const address = businessCard.Addresses;
                const addressContent = address?.valueArray[0].text;

                const email = businessCard.Emails;
                const emailContent = email?.valueArray[0].text;

                const website = businessCard.Websites;
                const websiteContent = website?.valueArray[0].text;

                const phone =
                  businessCard.MobilePhones || businessCard.OtherPhones;
                const phoneContent = phone?.valueArray[0].text;

                const cardData = {
                  name: contactName,
                  designation: designationContent,
                  companyName: companyContent,
                  address: addressContent,
                  email: emailContent,
                  website: websiteContent,
                  mobileNumber: phoneContent,
                };
                analyzingData = cardData;
              } else {
                setError('No business cards found.');
                throw new Error(
                  'Expected at least one business card in the result.',
                );
              }
            }).then(() => {
              navigate('/', { state: { analyzingData } });
            })
            .catch(error => {
              setError(
                'An error occurred while analyzing the business card' + error,
              );
            })
            .finally(() => {
              setLoader(false);
            });
        }, 5000); // Wait for 8 seconds before making the GET request
      })

      .catch(error => {
        setError('An error occurred while analyzing the business card' + error);
        setLoader(false);
      });
  };

  return (
    <Fragment>
      <Header innerPage={true} />
      <Root>
        {isCameraOpen && (
          <Camera
            onCapture={blob => {
              onCapture(blob);
            }}
            onClear={() => {
              onClear();
            }}
          />
        )}

        {frontImage && backImage && (
          <div className="pictures">
            <h2 style={{ marginBottom: 15 }}>Front Image</h2>
            <Preview style={{ borderRadius: 10 }} src={frontImage} />
            <h2 style={{ marginBottom: 15, marginTop: 15 }}>Back Image</h2>
            <Preview style={{ borderRadius: 10 }} src={backImage} />
          </div>
        )}
        {loader ? (
          <CircularProgress size={24} style={{ color: 'red' }} />
        ) : error ? (
          <div>
            <h6>Error handling</h6>
            <p style={{ color: 'red' }}>{error.toString()}</p>
          </div>
        ) : null}

        <Footer>
          {frontImage && backImage ? null : (
            <Fragment>
              <Button
                className="loginnow picturetake"
                onClick={() => setIsCameraOpen(true)}>
                <FlipCameraAndroid /> Open Camera
              </Button>
              <Button
                className="loginnow picturetake"
                onClick={() => {
                  setIsCameraOpen(false);
                  setFrontImage(undefined);
                  setBackImage(undefined);
                  navigate('/');
                }}>
                <NoPhotography /> Close Camera
              </Button>
            </Fragment>
          )}

          {frontImage && backImage && (
            <Button
              className="loginnow picturetake"
              onClick={() => mergeImages()}>
              <Merge /> Analyze Images
            </Button>
          )}
        </Footer>
      </Root>
      <GlobalStyle />
    </Fragment>
  );
}
