import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  div#root {
    height: 100%;
  }
`;

export const Root = styled.main`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 50px 0 100px;
`;

export const Preview = styled.img`
  width: 100%;
  height: auto;
`;

export const Footer = styled.footer`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  padding: 15px 0;
  gap: 10px;

  button {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  @media (max-width: 768px) {
    button.loginnow.picturetake {
      font-size: 14px !important;
      padding: 8px 8px !important;
    }

    .loginnow.picturetake svg {
      font-size: 20px !important;
    }
  }
`;
