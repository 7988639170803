import React from 'react';

export default function Cash() {
  return (
    <svg
      width="50"
      height="28"
      viewBox="0 0 50 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M25.039 0.23702C31.5234 0.23702 38.0078 0.23702 44.4921 0.23702C46.888 0.23702 48.7109 1.65629 49.1796 3.89587C49.2578 4.26046 49.2708 4.63806 49.2708 5.01567C49.2708 11.0443 49.2838 17.073 49.2708 23.1016C49.2708 25.4714 47.8906 27.2683 45.6901 27.75C45.2994 27.8412 44.8828 27.8672 44.4791 27.8672C31.5234 27.8672 18.5807 27.8672 5.62496 27.8672C2.76038 27.8672 0.820272 25.9401 0.807251 23.0756C0.79423 17.0599 0.79423 11.0573 0.807251 5.04171C0.807251 2.17712 2.76038 0.250041 5.62496 0.250041C12.1093 0.223999 18.5807 0.23702 25.039 0.23702ZM25 26.3959C31.4713 26.3959 37.9296 26.3959 44.401 26.3959C46.5494 26.3959 47.7994 25.1459 47.7994 23.0235C47.7994 17.0469 47.7994 11.0704 47.7994 5.09379C47.7994 2.95837 46.5364 1.70837 44.401 1.70837C31.4974 1.70837 18.6067 1.70837 5.70308 1.70837C5.3385 1.70837 4.94788 1.73442 4.59631 1.82556C3.151 2.20317 2.30465 3.38806 2.30465 5.04171C2.30465 11.0573 2.30465 17.0599 2.30465 23.0756C2.30465 23.2188 2.30465 23.362 2.31767 23.5183C2.40881 24.6901 2.95569 25.5756 4.0234 26.0443C4.57027 26.2917 5.20829 26.3959 5.80725 26.3959C12.2005 26.3959 18.6067 26.3959 25 26.3959Z" />
      <path d="M25.052 24.1824C19.8046 24.1824 14.5572 24.1824 9.30981 24.1824C8.45044 24.1824 8.26815 24.0131 8.20304 23.1407C8.08586 21.7345 6.90096 20.5756 5.48169 20.4714C4.70044 20.4194 4.50513 20.198 4.50513 19.4037C4.50513 15.836 4.50513 12.2553 4.50513 8.68758C4.50513 7.89331 4.70044 7.67196 5.49471 7.61987C6.94002 7.51571 8.09888 6.35685 8.21606 4.89852C8.28117 4.10425 8.4895 3.90894 9.28377 3.90894C19.8176 3.90894 30.3385 3.90894 40.8723 3.90894C41.6536 3.90894 41.8749 4.10425 41.927 4.89852C42.0442 6.34383 43.2161 7.50268 44.6484 7.60685C45.4296 7.65893 45.6249 7.88029 45.6249 8.67456C45.6249 12.2423 45.6249 15.823 45.6249 19.3907C45.6249 20.185 45.4166 20.3933 44.6353 20.4584C43.203 20.5626 42.0312 21.7345 41.914 23.1798C41.8489 23.974 41.6405 24.1694 40.8463 24.1694C35.5859 24.1824 30.3124 24.1824 25.052 24.1824ZM5.97648 19.0522C6.91398 19.2345 7.70825 19.6511 8.37231 20.3022C9.0494 20.9662 9.427 21.7996 9.64836 22.698C19.9478 22.698 30.1952 22.698 40.4687 22.698C40.9374 20.7188 42.1614 19.4819 44.1275 19.0261C44.1275 15.6928 44.1275 12.3855 44.1275 9.05216C42.1353 8.58341 40.9244 7.34644 40.4556 5.38029C30.1692 5.38029 19.9218 5.38029 9.64836 5.38029C9.19263 7.37248 7.95565 8.58341 5.97648 9.05216C5.97648 12.3855 5.97648 15.6798 5.97648 19.0522Z" />
      <path d="M26.8489 9.13029C27.1093 9.67717 27.3307 10.172 27.6041 10.7579C27.9427 10.7579 28.3593 10.7449 28.776 10.7579C29.3489 10.7709 29.6614 11.0574 29.6354 11.5261C29.6223 11.9688 29.3229 12.2162 28.763 12.2293C28.3854 12.2423 28.0208 12.2423 27.6432 12.2423C27.6171 12.2423 27.5781 12.2683 27.552 12.2813C26.8489 14.6381 25.1692 15.5235 22.7083 15.3282C22.8645 15.5496 22.9426 15.6798 23.0468 15.797C23.9062 16.9037 24.7525 18.0105 25.6119 19.1043C25.9765 19.573 25.9635 20.0287 25.5859 20.3022C25.2213 20.5756 24.8176 20.4845 24.4661 20.0157C23.2031 18.4011 21.9531 16.7865 20.7161 15.1589C20.5598 14.9506 20.5078 14.573 20.5598 14.3126C20.638 13.961 20.9895 13.8699 21.3411 13.8699C22.2005 13.8699 23.0598 13.8829 23.9192 13.8569C24.8567 13.8308 25.69 13.1668 25.9635 12.2553C25.7812 12.2423 25.6119 12.2293 25.4426 12.2293C24.0755 12.2293 22.7083 12.2293 21.3541 12.2293C20.8203 12.2293 20.5208 11.9949 20.4947 11.5652C20.4557 11.0834 20.7812 10.7579 21.3671 10.7579C22.7213 10.7449 24.0624 10.7579 25.4166 10.7579C25.5859 10.7579 25.7682 10.7579 25.9505 10.7579C25.8203 9.96363 25.052 9.24748 24.1666 9.16936C23.3333 9.09123 22.4869 9.13029 21.6406 9.10425C21.4453 9.10425 21.1979 9.15634 21.0677 9.06519C20.8463 8.89592 20.5598 8.64852 20.5468 8.41415C20.5338 8.17977 20.7942 7.90633 20.9895 7.71102C21.0937 7.60686 21.3281 7.6329 21.5104 7.6329C23.8932 7.6329 26.289 7.6329 28.6718 7.6329C28.802 7.6329 28.9322 7.6329 29.0624 7.65894C29.4661 7.73706 29.7005 7.98446 29.6744 8.41415C29.6484 8.83082 29.414 9.07821 28.9973 9.10425C28.4244 9.13029 27.8645 9.11727 27.2916 9.11727C27.1223 9.10425 27.0051 9.11727 26.8489 9.13029Z" />
      <path d="M10.4036 14.0391C10.4036 12.4505 11.6926 11.1484 13.2812 11.1484C14.8567 11.1484 16.1718 12.4375 16.1848 14.013C16.1978 15.6016 14.8697 16.9427 13.2812 16.9297C11.6796 16.9297 10.4036 15.6276 10.4036 14.0391ZM11.8749 14.0391C11.8749 14.8333 12.5129 15.4714 13.2942 15.4583C14.0624 15.4453 14.7265 14.7682 14.7004 14.013C14.6874 13.2578 14.0364 12.6198 13.2942 12.6198C12.4999 12.6328 11.8749 13.2448 11.8749 14.0391Z" />
      <path d="M39.6224 14.1042C39.5833 15.6927 38.2552 16.9818 36.6927 16.9427C35.0911 16.9036 33.789 15.5495 33.8411 13.974C33.8932 12.3854 35.2474 11.1094 36.7968 11.1615C38.4114 11.2135 39.6614 12.5156 39.6224 14.1042ZM36.7708 12.6328C35.9895 12.6198 35.3385 13.2578 35.3255 14.0391C35.3255 14.8073 35.9765 15.4713 36.7448 15.4713C37.5 15.4713 38.138 14.8463 38.151 14.0781C38.177 13.2838 37.552 12.6458 36.7708 12.6328Z" />
    </svg>
  );
}
